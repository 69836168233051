<template>
    <div class="container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item>Detail</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="goods-wrapper">
            <div class="img-display">
                <div class="cur-img-box">
                    <div v-if="curImgIndex > 0" class="previous-btn" @click="previous">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                    <div v-if="curImgIndex < (curPics.length - 1)" class="next-btn" @click="next">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <img class="cur-img" :src="curPics[curImgIndex] ? curPics[curImgIndex].full_url : detail.good.pic_url" />
                </div>
                <div class="img-list-box">
                    <div class="img-list" :style="`transform: translateX(-${25.33 * curImgIndex}%)`">
                        <div v-for="(item,index) in curPics" :key="index" class="img-item" @click="changeCurImgIndex(index)">
                            <img class="img" :src="item.full_url" />
                            <div v-if="index != curImgIndex" class="img-mask"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-info">
                <div class="name">{{detail.good.good_title}}</div>
                <div class="price">
                    <span class="c-price">{{$currencySymbol}}{{detail.good.price_trans}}</span>
                    <!-- <span class="o-price" style="text-decoration: line-through;">{{$currencySymbol}}888</span> -->
                </div>
                <div v-for="(attr,key) in specs.attrs" :key="key" class="sku-box">
                    <div class="label">{{key}}</div>
                    <div class="sku-list">
                        <el-tag v-for="(val,index) in attr" 
                        :key="index" 
                        class="sku-item" 
                        :type="val.active ? 'danger' : 'info'" 
                        @click="changeActive(key,index)">
                            {{val.value}}
                        </el-tag>
                    </div>
                </div>
                <!-- <div class="count-box">
                    <el-input-number v-model="num" :step="1" :min="1" :max="100" step-strictly @change="changeVal"></el-input-number>
                </div>
                <div class="add-cart">
                    <div class="add-btn" @click="addCart">Add to Cart</div>
                    <div class="like" @mouseenter="enter1" @mouseleave="leave1" @click="collect" :style="`border: 2px solid ${isActive1 ? '#fe5252' : '#999999'};`">
                        <img v-if="isActive1" class="icon" src="@/assets/images/index/like-active.png" />
                        <img v-else class="icon" src="@/assets/images/index/like.png" />
                    </div>
                </div> -->
                <div class="buy-box">
                    <div class="buy-btn" @click="toPayment">Buy now</div>
                    <!-- <div class="coupon" @mouseenter="enter2" @mouseleave="leave2">
                        <img v-if="isActive2" class="icon" src="@/assets/images/index/coupon-active.png" />
                        <img v-else class="icon" src="@/assets/images/index/coupon.png" />
                    </div> -->
                </div>
				<div class="balance">
					Available Balance:
					<span class="num">{{$currencySymbol}}{{balance}}</span>
				</div>
                <!-- <div class="payment-method">
                    <div class="label">Method of payment</div>
                    <div class="payment-item">
                        <img class="img" src="@/assets/images/index/pay-img.png" />
                    </div>
                </div> -->
                <div class="divide-line"></div>
                <div class="detail">
                    <div class="label-item">
                        <div class="label">Detail</div>
                        <i :class="isDisplay ? 'el-icon-minus on' : 'el-icon-plus'" @click="isDisplay = !isDisplay"></i>
                    </div>
                    <div v-show="isDisplay" v-html="detail.good.content" class="detail-content"></div>
                </div>
                <div class="divide-line"></div>
                <div class="contact-list">
                    <img v-for="(item,index) in contactList" :key="index" class="contact-item" :src="item.logo" @click="toUrl(item.url)" />
                </div>
            </div>
        </div>
        <!-- <div class="comment-box">
            <div class="comment-btn" @click="toComment">To comment on ▸</div>
        </div> -->
        <div class="recommend-box">
            <div class="label">You May Also Like</div>
            <div class="list">
                <div v-for="(item,index) in detail.like" :key="index" class="list-item">
                    <div class="click-info" @click="toDetail(item.id)">
                        <img class="img" :src="item.pic_url" />
                        <div class="name">{{item.good_title}}</div>
                    </div>
                    <div class="price">{{$currencySymbol}}{{item.price_trans}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                goods_id: '',
                detail: {
                    good: {},
                    like: []
                },
                specs: {
                    attrs: {},
                    sku: []
                },
                curImgIndex: 0,
                num: 1,
                fan: false,
                isActive1: false,
                isActive2: false,
                isDisplay: true,
				balance: 0
            }
        },
        created() {
            this.goods_id = this.$route.query.id || ''
            this.getDetail()
			this.getBalance()
        },
        computed: {
            curGoodsId() {
                return this.$route.query.id
            },
            
            curSpec() {
                let cur_spec = {}
                Object.keys(this.specs.attrs).forEach(key => {
                    this.specs.attrs[key].forEach(item => {
                        if(item.active) {
                            cur_spec[key] = item.value
                        }
                    })
                })
                
                return cur_spec
            },
            
            curPics() {
                let pics = []
                
                let all_status = []
                
                // Object.keys(this.curSpec).forEach(s_key => {
                //     all_status = this.specs.sku.filter(obj => obj[s_key] == this.curSpec[s_key])
                // })
                all_status = this.specs.sku.filter(obj => {
                    return JSON.stringify(obj) == JSON.stringify({...obj, ...this.curSpec})
                })
                pics = all_status.length > 0 ? all_status[0].pic : []
                
                return pics
            },
            
            contactList() {
                return this.$store.state.contactList
            }
        },
        watch: {
            curGoodsId(val) {
                // console.log(val)
                this.goods_id = val
                this.getDetail()
            },
            
            curPics() {
                this.curImgIndex = 0
            }
        },
        methods: {
            getDetail() {
                this.$api.goods_detail({
                    good_id: this.goods_id
                },true).then(res => {
                    this.detail = res.data
                    Object.keys(this.detail.good.sku.attrs).forEach(key => {
                         this.detail.good.sku.attrs[key] = this.detail.good.sku.attrs[key].map((item,index) => {
                             return {
                                 value: item,
                                 active: index == 0 ? true : false
                             }
                         })
                    })
                    this.specs = this.detail.good.sku
                    this.fan = this.detail.good.fan
                    this.isActive1 = this.fan
                })
            },
            
            changeCurImgIndex(index) {
                this.curImgIndex = index
            },
            
            previous() {
                this.curImgIndex--
            },
            
            next() {
                this.curImgIndex++
            },
            
            changeActive(key,index) {
                this.specs.attrs[key] = this.specs.attrs[key].map(item => {
                    item.active = false
                    
                    return item
                })
                this.specs.attrs[key][index].active = true
            },
            
            changeVal(val) {
                // console.log(val)
            },
            
            enter1() {
                if(!this.fan) {
                    this.isActive1 = true
                }
            },
            
            leave1() {
                if(!this.fan) {
                    this.isActive1 = false
                }
            },
            
            enter2() {
                this.isActive2 = true
            },
            
            collect() {
                this.$api.collect({
                    good_id: this.goods_id
                },true).then(res => {
                    this.fan = !this.fan
                    this.isActive1 = !this.isActive1
                    // this.$message({
                    //     message: res.message,
                    //     type: 'success'
                    // })
                })
            },
            
            addCart() {
                this.$api.addCart({
                    good_id: this.goods_id,
                    sku: JSON.stringify(this.curSpec),
                    num: this.num
                },true).then(res => {
                    this.$store.dispatch('getCartList')
                    this.$message({
                        message: res.message,
                        type: 'success'
                    })
                })
            },
            
            leave2() {
                this.isActive2 = false
            },
			
			getBalance() {
				this.$api.user_info().then(res => {
					this.balance = Number(res.data.balance)
				})
			},
            
            toPayment() {
				// if(this.detail.good.url) {
				// 	location.href = this.detail.good.url
				// }
                this.$router.push({
                    path: '/payment',
                    query: {
                        type: 'unit',
                        goods: JSON.stringify({
                            id: this.detail.good.id,
                            good_name: this.detail.good.good_title,
                            picture: this.detail.good.pic_url,
                            per_price: this.detail.good.price_trans,
                            sku: JSON.stringify(this.curSpec),
                            num: this.num
                        })
                    }
                })
            },
            
            toComment() {
                this.$router.push({
                    path: '/comment',
                    query: {
                        id: this.goods_id
                    }
                })
            },
            
            toUrl(url) {
                window.open(url)
            },
            
            toDetail(id) {
                this.$router.push({
                    path: '/detail',
                    query: {
                        id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            width: 100%;
            
            .goods-wrapper {
                // display: flex;
                width: 100%;
                box-sizing: border-box;
                padding-bottom: 5rem;
                margin-top: 2.4rem;
                
                .img-display {
                    // width: 60rem;
                    // margin-right: 2.4rem;
                    width: 100%;
                    margin-bottom: 3rem;
                    
                    .cur-img-box {
                        position: relative;
                        width: 100%;
                        cursor: pointer;
                        
                        &:hover {
                            .previous-btn,
                            .next-btn {
                                color: rgba(102,102,102,1);
                                background: rgba(255,255,255,1);
                            }
                            
                            .previous-btn {
                                left: 1rem;
                            }
                            
                            .next-btn {
                                right: 1rem;
                            }
                        }
                        
                        .previous-btn,
                        .next-btn {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 5rem;
                            height: 5rem;
                            border-radius: .5rem;
                            font-size: 2.8rem;
                            color: rgba(102,102,102,5);
                            white-space: nowrap;
                            transition: all 0.3s ease;
                            background: rgba(255,255,255,.5);
                            cursor: pointer;
                            
                            i {
                                font-weight: bold;
                            }
                        }
                        
                        .previous-btn {
                            left: 2.5rem;
                        }
                        
                        .next-btn {
                            right: 2.5rem;
                        }
                        
                        .cur-img {
                            display: block;
                            width: 100%;
                        }
                    }
                    
                    .img-list-box {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 1rem 0;
                        overflow-x: hidden;
                        
                        .img-list {
                            display: flex;
                            width: 100%;
                            transition: all 0.3s ease;
                            
                            .img-item {
                                position: relative;
                                min-width: 24%;
                                max-width: 24%;
                                cursor: pointer;
                                
                                &:not(:last-child) {
                                    margin-right: 1.33%;
                                }
                                
                                .img {
                                    display: block;
                                    width: 100%;
                                }
                                
                                .img-mask {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: rgba(255,255,255,.7);
                                }
                            }
                        }
                    }
                }
                
                .right-info {
                    // width: calc(100% - 62.4rem);
                    width: 100%;
                    
                    .name {
                        width: 100%;
                        font-size: 2.8rem;
                        font-weight: bold;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .price {
                        width: 100%;
                        margin: 1.5rem 0 3rem;
                        word-break: break-all;
                        
                        .c-price {
                            font-size: 2.4rem;
                            font-weight: bold;
                            color: #d1466e;
                        }
                        
                        .o-price {
                            margin-left: 1rem;
                            font-size: 1.8rem;
                            font-weight: 300;
                            color: #ACACAC;
                        }
                    }
                    
                    .sku-box {
                        width: 100%;
                        margin-bottom: 1.5rem;
                        
                        .label {
                            width: 100%;
                            margin-bottom: .5rem;
                            font-size: 1.4rem;
                            color: #000000;
                            word-wrap: break-word;
                        }
                        
                        .sku-list {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            
                            .sku-item {
                                margin: .5rem 1rem .5rem 0;
                                cursor: pointer;
                            }
                        }
                    }
                    
                    .count-box {
                        width: 100%;
                        margin: 3rem 0;
                    }
                    
                    .add-cart {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        
                        .add-btn {
                            width: 100%;
                            max-width: 34rem;
                            height: 5rem;
                            line-height: 5rem;
                            box-sizing: border-box;
                            border: 2px solid #333333;
                            border-radius: 2px;
                            font-size: 1.6rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #FFFFFF;
                            cursor: pointer;
                            transition: all 0.3s;
                            
                            &:hover {
                                color: #FFFFFF;
                                background-color: #333333;
                            }
                        }
                        
                        .like {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 5rem;
                            max-width: 5rem;
                            height: 5rem;
                            box-sizing: border-box;
                            margin-left: 1rem;
                            border-radius: 2px;
                            background-color: #FFFFFF;
                            cursor: pointer;
                            
                            .icon {
                                min-width: 2rem;
                                max-width: 2rem;
                                height: 2rem;
                            }
                        }
                    }
                    
                    .buy-box {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin: 3rem 0;
                        
                        .buy-btn {
                            width: 100%;
                            max-width: 34rem;
                            height: 5rem;
                            line-height: 5rem;
                            box-sizing: border-box;
                            border-radius: 2px;
                            font-size: 1.8rem;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #000000;
                            cursor: pointer;
                        }
                        
                        .coupon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 5rem;
                            max-width: 5rem;
                            height: 5rem;
                            box-sizing: border-box;
                            margin-left: 1rem;
                            
                            .icon {
                                min-width: 4.4rem;
                                max-width: 4.4rem;
                                height: 4.4rem;
                                cursor: pointer;
                            }
                        }
                    }
					
					.balance {
						width: 100%;
						font-size: 1.5rem;
						color: #333333;
						word-break: break-all;
						
						.num {
							margin-left: 6px;
							font-size: 2.8rem;
							font-weight: bold;
						}
					}
                    
                    .payment-method {
                        width: 100%;
                        
                        .label {
                            width: 100%;
                            margin-bottom: 1.5rem;
                            font-size: 1.6rem;
                            font-weight: bold;
                            color: #333333;
                            word-wrap: break-word;
                        }
                        
                        .payment-item {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            padding: .8rem 1rem;
                            border: 1px solid #d9d9d9;
                            border-radius: 2px;
                            
                            .img {
                                width: 8rem;
                            }
                        }
                    }
                    
                    .divide-line {
                        width: 100%;
                        height: 1px;
                        margin: 3rem 0 2rem;
                        background-color: #e9e9e9;
                    }
                    
                    .detail {
                        width: 100%;
                        
                        .label-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 2rem;
                            
                            .label {
                                max-width: 100%;
                                font-size: 1.6rem;
                                font-weight: bold;
                                color: #333333;
                                word-wrap: break-word;
                            }
                            
                            i {
                                margin-left: 3rem;
                                font-size: 1.8rem;
                                font-weight: bold;
                                color: #333333;
                                cursor: pointer;
                                transition: all 0.2s ease-in;
                            }
                            
                            .on {
                                transform: rotate(180deg);
                            }
                        }
                        
                        .detail-content {
                            width: 100%;
                            transition: all 0.2s linear;
                        }
                    }
                    
                    .contact-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        
                        .contact-item {
                            min-width: 4.4rem;
                            max-width: 4.4rem;
                            height: 4.4rem;
                            margin: 0 1rem 1rem 0;
                            cursor: pointer;
                        }
                    }
                }
            }
            
            .comment-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 5rem 0;
                border-top: 1px solid #e9e9e9;
                border-bottom: 1px solid #e9e9e9;
                
                .comment-btn {
                    width: 34rem;
                    height: 5rem;
                    line-height: 5rem;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-size: 1.8rem;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #fe5252;
                    cursor: pointer;
                    transition: all 0.3s;
                    
                    &:hover {
                        background-color: #333333;
                    }
                }
            }
            
            .recommend-box {
                width: 100%;
                box-sizing: border-box;
                padding: 5rem 0;
				border-top: 1px solid #e9e9e9;
                
                .label {
                    width: 100%;
                    margin-bottom: 3rem;
                    font-size: 2.4rem;
                    // font-weight: bold;
                    color: #333333;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .list {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    
                    .list-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 24%;
                        margin-left: 1.33%;
                        
                        &:nth-child(4n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+5) {
                            margin-top: 1.6rem;
                        }
                        
                        .click-info {
                            width: 100%;
                            
                            .img {
                                display: block;
                                width: 100%;
                                cursor: pointer;
                            }
                            
                            .name {
                                width: 100%;
                                padding: 1.4rem 0 1rem;
                                font-size: 1.4rem;
                                color: #333333;
                                text-align: center;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                            }
                        }
                        
                        .price {
                            width: 100%;
                            font-size: 1.6rem;
                            font-weight: bold;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            width: 100%;
            box-sizing: border-box;
            padding: $h5-box-padding;
            
            .goods-wrapper {
                width: 100%;
                box-sizing: border-box;
                padding-bottom: 2.5rem;
                margin-top: 2rem;
                
                .img-display {
                    width: 100%;
                    margin-bottom: 3rem;
                    
                    .cur-img-box {
                        position: relative;
                        width: 100%;
                        cursor: pointer;
                        
                        .previous-btn,
                        .next-btn {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 4rem;
                            height: 4rem;
                            border-radius: .4rem;
                            font-size: 2.2rem;
                            color: #333333;
                            white-space: nowrap;
                            transition: all 0.3s ease;
                            background: #eeeeee;
                            cursor: pointer;
                            
                            i {
                                font-weight: bold;
                            }
                        }
                        
                        .previous-btn {
                            left: 1.5rem;
                        }
                        
                        .next-btn {
                            right: 1.5rem;
                        }
                        
                        .cur-img {
                            display: block;
                            width: 100%;
                        }
                    }
                    
                    .img-list-box {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 1rem 0;
                        overflow-x: hidden;
                        
                        .img-list {
                            display: flex;
                            width: 100%;
                            transition: all 0.3s ease;
                            
                            .img-item {
                                position: relative;
                                min-width: 24%;
                                max-width: 24%;
                                cursor: pointer;
                                
                                &:not(:last-child) {
                                    margin-right: 1.33%;
                                }
                                
                                .img {
                                    display: block;
                                    width: 100%;
                                }
                                
                                .img-mask {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: rgba(255,255,255,.7);
                                }
                            }
                        }
                    }
                }
                
                .right-info {
                    width: 100%;
                    
                    .name {
                        width: 100%;
                        font-size: 2rem;
                        font-weight: bold;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .price {
                        width: 100%;
                        margin: 1rem 0 2.5rem;
                        word-break: break-all;
                        
                        .c-price {
                            font-size: 2rem;
                            font-weight: bold;
                            color: #d1466e;
                        }
                        
                        .o-price {
                            margin-left: 1rem;
                            font-size: 1.4rem;
                            font-weight: 300;
                            color: #ACACAC;
                        }
                    }
                    
                    .sku-box {
                        width: 100%;
                        margin-bottom: 1.2rem;
                        
                        .label {
                            width: 100%;
                            margin-bottom: .4rem;
                            font-size: 1.4rem;
                            color: #000000;
                            word-wrap: break-word;
                        }
                        
                        .sku-list {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            
                            .sku-item {
                                margin: .5rem 1rem .5rem 0;
                                cursor: pointer;
                            }
                        }
                    }
                    
                    .count-box {
                        width: 100%;
                        margin: 2.5rem 0;
                    }
                    
                    .add-cart {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        
                        .add-btn {
                            width: 100%;
                            height: 5rem;
                            line-height: 5rem;
                            box-sizing: border-box;
                            border: 2px solid #333333;
                            border-radius: 2px;
                            font-size: 1.6rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #FFFFFF;
                            cursor: pointer;
                            transition: all 0.3s;
                            
                            &:hover {
                                color: #FFFFFF;
                                background-color: #333333;
                            }
                        }
                        
                        .like {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 5rem;
                            max-width: 5rem;
                            height: 5rem;
                            box-sizing: border-box;
                            margin-left: 1rem;
                            border-radius: 2px;
                            background-color: #FFFFFF;
                            cursor: pointer;
                            
                            .icon {
                                min-width: 2rem;
                                max-width: 2rem;
                                height: 2rem;
                            }
                        }
                    }
                    
                    .buy-box {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin: 2rem 0;
                        
                        .buy-btn {
                            width: 100%;
                            height: 5rem;
                            line-height: 5rem;
                            box-sizing: border-box;
                            border-radius: 2px;
                            font-size: 1.8rem;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #000000;
                            cursor: pointer;
                        }
                        
                        .coupon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 5rem;
                            max-width: 5rem;
                            height: 5rem;
                            box-sizing: border-box;
                            margin-left: 1rem;
                            
                            .icon {
                                min-width: 4.4rem;
                                max-width: 4.4rem;
                                height: 4.4rem;
                                cursor: pointer;
                            }
                        }
                    }
					
					.balance {
						width: 100%;
						font-size: 1.4rem;
						color: #333333;
						word-break: break-all;
						
						.num {
							margin-left: 4px;
							font-size: 2.4rem;
							font-weight: bold;
						}
					}
                    
                    .payment-method {
                        width: 100%;
                        
                        .label {
                            width: 100%;
                            margin-bottom: 1.5rem;
                            font-size: 1.6rem;
                            font-weight: bold;
                            color: #333333;
                            word-wrap: break-word;
                        }
                        
                        .payment-item {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            padding: .8rem 1rem;
                            border: 1px solid #d9d9d9;
                            border-radius: 2px;
                            
                            .img {
                                width: 8rem;
                            }
                        }
                    }
                    
                    .divide-line {
                        width: 100%;
                        height: 1px;
                        margin: 2.5rem 0 2rem;
                        background-color: #e9e9e9;
                    }
                    
                    .detail {
                        width: 100%;
                        
                        .label-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 2rem;
                            
                            .label {
                                max-width: 100%;
                                font-size: 1.6rem;
                                font-weight: bold;
                                color: #333333;
                                word-wrap: break-word;
                            }
                            
                            i {
                                margin-left: 3rem;
                                font-size: 1.8rem;
                                font-weight: bold;
                                color: #333333;
                                cursor: pointer;
                                transition: all 0.2s ease-in;
                            }
                            
                            .on {
                                transform: rotate(180deg);
                            }
                        }
                        
                        .detail-content {
                            width: 100%;
                            transition: all 0.2s linear;
                        }
                    }
                    
                    .contact-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        
                        .contact-item {
                            min-width: 4.4rem;
                            max-width: 4.4rem;
                            height: 4.4rem;
                            margin: 0 1rem 1rem 0;
                            cursor: pointer;
                        }
                    }
                }
            }
            
            .comment-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 4rem 0;
                border-top: 1px solid #e9e9e9;
                border-bottom: 1px solid #e9e9e9;
                
                .comment-btn {
                    width: 100%;
                    height: 5rem;
                    line-height: 5rem;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-size: 1.8rem;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #fe5252;
                    cursor: pointer;
                    transition: all 0.3s;
                    
                    &:hover {
                        background-color: #333333;
                    }
                }
            }
            
            .recommend-box {
                width: 100%;
                box-sizing: border-box;
                padding: 4rem 0;
				border-top: 1px solid #e9e9e9;
                
                .label {
                    width: 100%;
                    margin-bottom: 2.5rem;
                    font-size: 2rem;
                    // font-weight: bold;
                    color: #333333;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .list {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    
                    .list-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 48%;
                        margin-left: 4%;
                        
                        &:nth-child(2n+1) {
                            margin-left: 0;
                        }
                        
                        &:nth-child(n+3) {
                            margin-top: 1.5rem;
                        }
                        
                        .click-info {
                            width: 100%;
                            
                            .img {
                                display: block;
                                width: 100%;
                                cursor: pointer;
                            }
                            
                            .name {
                                width: 100%;
                                padding: 1.2rem 0 .8rem;
                                font-size: 1.4rem;
                                color: #333333;
                                text-align: center;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                            }
                        }
                        
                        .price {
                            width: 100%;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
</style>
